import { useState, useEffect } from "react";
import axios from "../api/axios"; 
function useCameraData(camId) {
  const [cameraData, setCameraData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (!camId) return;
    let isMounted = true; 
    let intervalId;
    const fetchCameraData = async () => {
      try {
        const response = await axios.get(`/backend_data/${camId}`);
        if (isMounted) {
          // console.log("Fetched camera data:", response.data);
          setCameraData(response.data);
          setLoading(false);
          setError(null);
        }
      } catch (err) {
        if (isMounted) {
          if (err.response && err.response.status === 500) {
            clearInterval(intervalId);
          }
          setError("Failed to fetch data");
          setLoading(false);
        }
      }
    };
    fetchCameraData();
    intervalId = setInterval(fetchCameraData, 1000); //can be changed to 1000 for 1
    return () => {
      clearInterval(intervalId);
      isMounted = false;
    };
  }, [camId]);
  return { cameraData, loading, error };
}
export default useCameraData;
