import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Form, ListGroup, FormControl } from "react-bootstrap";
import "../styles/style.css";
import { useNavigate } from "react-router-dom";
import axiosphp from "../api/axiosphp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const MediaPlayer = ({ url }) => {
  const mediaRef = useRef(null);

  const handleFullscreen = () => {
    const element = mediaRef.current;
    if (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) { /* Safari */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { /* IE11 */
        element.msRequestFullscreen();
      }
    }
  };

  const getMediaType = () => {
    if (url.includes('/video_feed')) {
      return 'image';
    } else if (/\.(mp4|webm|ogg)$/.test(url)) {
      return 'video';
    } else if (url.startsWith('rtsp://')) {
      return 'rtsp';
    } else {
      return 'iframe';
    }
  };

  const mediaType = getMediaType();

  return (
    <div className="media-container">
      {mediaType === 'image' ? (
        <img
          ref={mediaRef}
          src={url}
          alt="Live Stream"
          className="media-element"
        />
      ) : mediaType === 'video' ? (
        <video ref={mediaRef} controls className="media-element">
          <source src={url} type={`video/${url.split('.').pop()}`} />
          Your browser does not support the video tag.
        </video>
      ) : mediaType === 'rtsp' ? (
        <video ref={mediaRef} controls className="media-element">
          <source src={url} type="application/x-rtsp" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <iframe
          ref={mediaRef}
          src={url}
          title="Video"
          className="media-element"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
      <Button
        variant="secondary"
        size="sm"
        className="fullscreen-button"
        onClick={handleFullscreen}
      >
        Fullscreen
      </Button>
    </div>
  );
};




function CommandMcamera() {
  const navigate = useNavigate();
  const [showAddCamModal, setShowAddCamModal] = useState(false);
  const [showRemoveCamModal, setShowRemoveCamModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [camName, setCamName] = useState('');
  const [camLocation, setCamLocation] = useState('');
  const [camUrl, setCamUrl] = useState('');
  const [cameras, setCameras] = useState([]);
  const [selectedCamId, setSelectedCamId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const goc = (event) => {
    event.preventDefault();
    navigate("/commandc");
  };

  const gote = (event) => {
    event.preventDefault();
    navigate("/commandte");
  };

  const gota = (event) => {
    event.preventDefault();
    navigate("/commandta");
  };

  const goa = (event) => {
    event.preventDefault();
    navigate("/commanda");
  };

  const goh = (event) => {
    event.preventDefault();
    navigate("/commandh");
  };

  const gomc = (event) => {
    event.preventDefault();
    navigate("/commandmc");
  };

  useEffect(() => {
    fetchCameras();
  }, []);

  const fetchCameras = () => {
    axiosphp.get("/fetch_cameras.php")
      .then(response => {
        if (response.data && response.data.cameras) {
          setCameras(response.data.cameras);
        } else {
          console.error('Invalid response format');
          toast.error('Error: Invalid response format');
          setCameras([]);
        }
      })
      .catch(error => {
        console.error('Error fetching cameras:', error);
        toast.error(`There was an error: ${error.message}`, { autoClose: 3000 });
        setCameras([]);
      });
  };

  const handleAddCam = () => {
    setShowAddCamModal(true);
  };

  const handleAddCamSubmit = () => {
    const newCam = { cam_name: camName, cam_location: camLocation, cam_url: camUrl };
    axiosphp.post("/add_camera.php", newCam)
      .then(response => {
        if (response.data.success) {
          setShowAddCamModal(false);
          toast.success("New camera added successfully!", { autoClose: 3000 });
          setCamName('');
          setCamLocation('');
          setCamUrl('');
          fetchCameras();
        } else {
          toast.error(`Error: ${response.data.message}`, { autoClose: 3000 });
        }
      })
      .catch(error => {
        toast.error(`There was an error: ${error.message}`, { autoClose: 3000 });
      });
  };

  const handleRemoveCam = () => {
    setShowRemoveCamModal(true);
  };

  const handleConfirmDelete = () => {
    if (selectedCamId) {
      axiosphp.post("/delete_camera.php", { cam_id: selectedCamId })
        .then(response => {
          if (response.data.success) {
            setShowConfirmModal(false);
            toast.success("Camera removed successfully!", { autoClose: 3000 });
            setSelectedCamId(null);
            fetchCameras();
          } else {
            toast.error(`Error: ${response.data.message}`, { autoClose: 3000 });
          }
        })
        .catch(error => {
          toast.error(`There was an error: ${error.message}`, { autoClose: 3000 });
        });
    }
  };



  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCameras = cameras.filter(camera => {
    if (!camera) return false;

    const searchLower = searchTerm.toLowerCase();
    const nameMatch = camera.camname ? camera.camname.toLowerCase().includes(searchLower) : false;
    const locationMatch = camera.camlocation ? camera.camlocation.toLowerCase().includes(searchLower) : false;
    const idMatch = camera.id ? camera.id.toString().includes(searchTerm) : false;

    return nameMatch || locationMatch || idMatch;
  });

  return (
    <div className="custom-container">
      <div className="wrapper">
        <div className="info-hct">
          <p><span className="high">Command Center </span> Multiple Camera </p>
        </div>
        <div>
          <div className="cam-container">
            {cameras.map((camera, index) => (
              <div key={index} className="camera">
                <h5 className="cameraname">{camera.camname}</h5>
                <p className="cameralocation">{camera.camlocation}</p>
                <MediaPlayer url={camera.camurl} />
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="add-remove-container">
            <Button onClick={handleAddCam} className="add-button">
              Add
            </Button>
            <Button onClick={handleRemoveCam} className="remove-button">
              Remove
            </Button>
          </div>
          <div className="his-buttons2">
            <Button onClick={goc} className="all-buttond h-buttonc">
              Civilian
            </Button>
            <Button onClick={gote} className="all-buttond h-buttonc">
              Traffic Enforcer
            </Button>
            <Button onClick={gota} className="all-buttond h-buttonc">
              Traffic Agency
            </Button>
            <Button onClick={goa} className="all-buttond h-button">
              Admin
            </Button>
            <Button onClick={goh} className="all-buttond h-button">
              Traffic History
            </Button>
            <Button onClick={gomc} className="all-buttond current-btnc h-buttonc">
              Multiple Camera
            </Button>
          </div>
        </div>
      </div>

      {/* Add Camera Modal */}
      <Modal show={showAddCamModal} onHide={() => setShowAddCamModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Camera</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formCamName">
              <Form.Label>Camera Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter camera name"
                value={camName}
                onChange={(e) => setCamName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formCamLocation" className="mt-3">
              <Form.Label>Camera Location</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter camera location"
                value={camLocation}
                onChange={(e) => setCamLocation(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formCamUrl" className="mt-3">
              <Form.Label>Camera URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter camera URL"
                value={camUrl}
                onChange={(e) => setCamUrl(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddCamModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddCamSubmit}>
            Add Camera
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Remove Camera Modal */}
      <Modal show={showRemoveCamModal} onHide={() => setShowRemoveCamModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Camera</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl
            type="text"
            placeholder="Search by ID, Name, or Location"
            value={searchTerm}
            onChange={handleSearchChange}
            className="mb-3"
          />
          <ListGroup>
            <ListGroup.Item>
              <strong>Cam ID | Cam Name | Cam Location</strong>
            </ListGroup.Item>
            {filteredCameras.map(camera => (
              <ListGroup.Item
                key={camera.id}
                active={camera.id === selectedCamId}
                onClick={() => setSelectedCamId(camera.id)}
                action
              >
                {camera.id} | {camera.camname} | {camera.camlocation}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRemoveCamModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => setShowConfirmModal(true)} disabled={!selectedCamId}>
            Delete Camera
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Confirm Delete Modal */}
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this camera?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Toast Notifications */}
      <ToastContainer closeButton={false} />
    </div>
  );
}

export default CommandMcamera;