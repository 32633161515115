import React, { useState, useEffect, useContext } from "react";
import { Button, Table, Modal } from "react-bootstrap";
import "../styles/style.css";
import { useNavigate } from "react-router-dom";
import axiosphp from "../api/axiosphp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../context/UserContext";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

function CommandThistory() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [dataType, setDataType] = useState("hourly");
  const { setUser } = useContext(UserContext);
  const [showAnalytics, setShowAnalytics] = useState(false);

  const handleAnalyticsButtonClick = () => {
    setShowAnalytics(true);
  };

  const navigateTo = (path) => (event) => {
    event.preventDefault();
    navigate(path);
  };

  const logout = (event) => {
    setUser(null);
    event.preventDefault();
    navigate("/login");
  };

  useEffect(() => {
    axiosphp
      .get(`/fetch_traffic_history_admin.php?type=${dataType}`)
      .then((response) => {
        setData(response.data[dataType]);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [dataType]);

  const handleButtonClick = (type) => {
    setDataType(type);
  };

  const handleRowClick = (user, index) => {
    let id;
    switch (dataType) {
      case "hourly":
        id = user.h_id;
        break;
      case "daily":
        id = user.d_id;
        break;
      case "weekly":
        id = user.w_id;
        break;
      case "monthly":
        id = user.m_id;
        break;
      default:
        break;
    }
    setSelectedUser({ ...user, id });
    setSelectedRowIndex(index);
  };

  const handleDeleteButtonClick = () => {
    if (selectedUser) {
      setShowDeleteModal(true);
    } else {
      toast.info("Please select user to DELETE", { autoClose: 3000 });
    }
  };

  const handleDeleteConfirm = () => {
    axiosphp
      .delete(
        `/traffic_history_delete.php?id=${selectedUser.id}&type=${dataType}`
      )
      .then((response) => {
        if (response.data.success) {
          let newData;
          switch (dataType) {
            case "hourly":
              newData = data.filter((user) => user.h_id !== selectedUser.id);
              break;
            case "daily":
              newData = data.filter((user) => user.d_id !== selectedUser.id);
              break;
            case "weekly":
              newData = data.filter((user) => user.w_id !== selectedUser.id);
              break;
            case "monthly":
              newData = data.filter((user) => user.m_id !== selectedUser.id);
              break;
            default:
              break;
          }
          setData([...newData]);
          setSelectedUser(null);
          setShowDeleteModal(false);
          toast.success("User deleted successfully!", { autoClose: 3000 });
        } else {
          console.error("There was an error!", response.data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  // Function to format congestion time for display in the table
  const formatCongestionTime = (time) => {
    const totalSeconds = Math.floor(time);
    if (isNaN(totalSeconds)) return "0 seconds";
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (hours > 0) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ${minutes} minute${
        minutes !== 1 ? "s" : ""
      }`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ${seconds} second${
        seconds !== 1 ? "s" : ""
      }`;
    } else {
      return `${seconds} second${seconds !== 1 ? "s" : ""}`;
    }
  };

  // Function to format Y-axis labels
  const formatYAxis = (value) => {
    const totalSeconds = Math.floor(value);
    if (isNaN(totalSeconds)) return "0s";
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (hours > 0) {
      return `${hours}h`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    } else {
      return `${seconds}s`;
    }
  };

  // Function to format Tooltip values
  const formatTooltip = (value, name) => {
    if (name === "Congestion Time") {
      const totalSeconds = Math.floor(value);
      if (isNaN(totalSeconds)) return "0s";
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      if (hours > 0) {
        return `${hours}h ${minutes}m`;
      } else if (minutes > 0) {
        return `${minutes}m ${seconds}s`;
      } else {
        return `${seconds}s`;
      }
    }
    return value;
  };

  const congestionColors = {
    Gridlock: "#AB0003",
    Heavy: "#FF1519",
    Moderate: "#FFA800",
    Light: "#FFE500",
    Freeflow: "#05FF00",
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const dataPoint = payload[0].payload;
      const congestionLevel = dataPoint[`${dataType}_congestion`] || dataPoint.congestion;
      const congestionColor = congestionColors[congestionLevel] || "#000";
      const congestionTime =
        dataType === "weekly"
          ? dataPoint.w_total_congestion_time
          : dataType === "monthly"
          ? dataPoint.m_total_congestion_time
          : dataPoint.congestion_time;
  
      // Retrieve total vehicle using totalVehicleKey
      const totalVehicle = dataPoint[totalVehicleKey] || 0;
  
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "white",
            padding: "10px",
            border: "1px solid #ccc",
          }}
        >
          <p>
            <strong>Cam ID:</strong> {dataPoint.cam_id}
          </p>
          <p>
            <strong>Cam Location:</strong> {dataPoint.cam_location}
          </p>
          <p>
            <strong>Date:</strong> {dataPoint.date_time}
          </p>
          <p>
            <strong>Congestion Time:</strong>{" "}
            {formatCongestionTime(congestionTime)}
          </p>
          <p>
            <strong>Congestion:</strong>{" "}
            <span style={{ color: congestionColor }}>{congestionLevel}</span>
          </p>
          <p>
            <strong>Total Vehicle:</strong> {totalVehicle}
          </p>
        </div>
      );
    }
  
    return null;
  };

  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
        {payload.map((entry, index) => (
          <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <div
              style={{
                width: '10px',
                height: '10px',
                backgroundColor: entry.color,
                marginRight: '5px'
              }}
            ></div>
            <span>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };

  const renderTableRows = () => {
    return data.map((row, index) => {
      let id, congestion, totalVehicle, date, congestionTime;
  
      switch (dataType) {
        case "hourly":
        case "daily":
          id = row.cam_id;
          congestion = row[`${dataType}_congestion`] || "N/A";
          totalVehicle = row.total_vehicle || 0;
          date = row.date_time;
          congestionTime = formatCongestionTime(row.congestion_time);
          break;
        case "weekly":
          id = row.cam_id;
          congestion = row.weekly_congestion || "N/A";
          totalVehicle = row.w_total_vehicle || 0;
          date = `${row.start_date} / ${row.end_date}`;
          congestionTime = formatCongestionTime(row.w_total_congestion_time);
          break;
        case "monthly":
          id = row.cam_id;
          congestion = row.monthly_congestion || "N/A";
          totalVehicle = row.m_total_vehicle || 0;
          date = `${row.start_date} / ${row.end_date}`;
          congestionTime = formatCongestionTime(row.m_total_congestion_time);
          break;
        default:
          break;
      }
  
      return (
        <tr
          key={index}
          onClick={() => handleRowClick(row, index)}
          className={index === selectedRowIndex ? "selected" : ""}
        >
          <td>{id}</td>
          <td>{row.cam_location}</td>
          <td>{date}</td>
          <td>{congestion}</td>
          <td>{congestionTime}</td>
          <td>{totalVehicle}</td>
        </tr>
      );
    });
  };
  // Calculate maximum Y-axis value
  const maxCongestionTime =
    data.length > 0
      ? Math.max(
          ...data.map((d) =>
            dataType === "weekly"
              ? d.w_total_congestion_time || 0
              : dataType === "monthly"
              ? d.m_total_congestion_time || 0
              : d.congestion_time || 0
          )
        )
      : 0;
  const maxTotalVehicle =
    data.length > 0
      ? Math.max(
          ...data.map((d) =>
            dataType === "weekly"
              ? d.w_total_vehicle || 0
              : dataType === "monthly"
              ? d.m_total_vehicle || 0
              : d.total_vehicle || 0
          )
        )
      : 0;
  const maxY = Math.max(maxCongestionTime, maxTotalVehicle);

  // Define data keys based on dataType
  const congestionKey =
    dataType === "weekly"
      ? "w_total_congestion_time"
      : dataType === "monthly"
      ? "m_total_congestion_time"
      : "congestion_time";
  const totalVehicleKey =
    dataType === "weekly"
      ? "w_total_vehicle"
      : dataType === "monthly"
      ? "m_total_vehicle"
      : "total_vehicle";

 

  return (
    <div className="custom-container">
      <ToastContainer closeButton={false}/>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete Traffic Data?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Confirm
          </Button>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="info-hc">
        <p>
          <span className="highlight">Traffic </span> History Management
        </p>
      </div>
      <div className="table-and-buttons">
        {!showAnalytics ? (
          <>
            <Table className="table-dc" responsive>
              <thead className="theaderc">
                <tr>
                  <th>Cam ID</th>
                  <th>Cam Location</th>
                  <th>Date and Time</th>
                  <th>Congestion</th>
                  <th>Congestion Time</th>
                  <th>Total Vehicle</th>
                </tr>
              </thead>
              <tbody className="tablebodyc">{renderTableRows()}</tbody>
              <tfoot>
                <tr>
                  <td colSpan="6">
                    <div className="func-container">
                      <Button
                        className={`func-button data-button ${
                          dataType === "hourly" ? "selected-history" : ""
                        }`}
                        onClick={() => handleButtonClick("hourly")}
                      >
                        Hourly
                      </Button>
                      <Button
                        className={`func-button data-button ${
                          dataType === "daily" ? "selected-history" : ""
                        }`}
                        onClick={() => handleButtonClick("daily")}
                      >
                        Daily
                      </Button>
                      <Button
                        className={`func-button data-button ${
                          dataType === "weekly" ? "selected-history" : ""
                        }`}
                        onClick={() => handleButtonClick("weekly")}
                      >
                        Weekly
                      </Button>
                      <Button
                        className={`func-button data-button ${
                          dataType === "monthly" ? "selected-history" : ""
                        }`}
                        onClick={() => handleButtonClick("monthly")}
                      >
                        Monthly
                      </Button>
                      <Button
                        className="extra-button analytic-button"
                        onClick={handleAnalyticsButtonClick}
                      >
                        Analytics Mode
                      </Button>
                      <Button
                        className="extra-button"
                        onClick={handleDeleteButtonClick}
                      >
                        Delete
                      </Button>

                      <Button onClick={logout} className="current-func">
                        Logout
                      </Button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>

            <div className="info-datac">
              <div className="his-button">
                <Button
                  onClick={navigateTo("/commandc")}
                  className="all-buttonc  h-buttonc"
                >
                  Civilian
                </Button>
                <Button
                  onClick={navigateTo("/commandte")}
                  className="all-buttonc  h-buttonc"
                >
                  Traffic Enforcer
                </Button>
                <Button
                  onClick={navigateTo("/commandta")}
                  className="all-buttonc  h-buttonc"
                >
                  Traffic Agency
                </Button>
                <Button
                  onClick={navigateTo("/commanda")}
                  className="all-buttonc  h-buttonc"
                >
                  Admin
                </Button>
                <Button
                  onClick={navigateTo("/commandh")}
                  className="all-buttonc current-btnc  h-buttonc"
                >
                  Traffic History
                </Button>
                <Button
                  onClick={navigateTo("/commandmc")}
                  className="all-buttonc h-buttonc"
                >
                  Multiple Camera
                </Button>
              </div>
            </div>
          </>
        ) : (
          <div>
            <div className="func-chart">
              <Button
                onClick={() => handleButtonClick("hourly")}
                className={
                  dataType === "hourly" ? "selectedButton" : "extra-button"
                }
              >
                Hourly
              </Button>
              <Button
                onClick={() => handleButtonClick("daily")}
                className={
                  dataType === "daily" ? "selectedButton" : "extra-button"
                }
              >
                Daily
              </Button>
              <Button
                onClick={() => handleButtonClick("weekly")}
                className={
                  dataType === "weekly" ? "selectedButton" : "extra-button"
                }
              >
                Weekly
              </Button>
              <Button
                onClick={() => handleButtonClick("monthly")}
                className={
                  dataType === "monthly" ? "selectedButton" : "extra-button"
                }
              >
                Monthly
              </Button>
              <Button
                onClick={() => setShowAnalytics(false)}
                className="extra-button"
              >
                Back to Table
              </Button>
            </div>
            <div className="linechart">
              <LineChart
                width={1500}
                height={550}
                data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date_time" />
                <YAxis domain={[0, maxY]} tickFormatter={formatYAxis} />
                <Tooltip content={<CustomTooltip />} />
                <Legend content={<CustomLegend />} />
                <Line
                  type="monotone"
                  dataKey={congestionKey}
                  name="Congestion Time"
                  stroke="#91080A"
                />
                <Line
                  type="monotone"
                  dataKey={totalVehicleKey}
                  name="Total Vehicle"
                  stroke="#19054b"
                />
              </LineChart>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default CommandThistory;