import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/style.css";
import drainage from "../images/drainage.png";
import entrance from "../images/Entrance.png";
import exit from "../images/Exit.png";
import noenter from "../images/noentry.png";
import building from "../images/building.png";
import roadcons from "../images/roadcons.png";
import fire from "../images/fire.png";
import vaccident from "../images/vaccident.png";
import flood from "../images/flood.png";
import landslide from "../images/landslide.png";
import { Modal, Button, Form } from "react-bootstrap";
import axiosphp from "../api/axiosphp";
import { ToastContainer, toast } from "react-toastify";
import axios from "../api/axios";
import { useFetchCongestionData } from "../hooks/useFetchCongestionData";

const markerIcons = {
  "Entrance Only": entrance,
  "Exit Only": exit,
  "No Entry": noenter,
  "Drainage": drainage,
  "Building": building,
  "Road": roadcons,
  "Fire": fire,
  "Flood": flood,
  "Landslide": landslide,
  "Vehicular Accidents": vaccident,
};

const tcongestionColor = {
  Gridlock: "#AB0003",
  Heavy: "#FF1519",
  Moderate: "#FFA800",
  Light: "#FFE500",
  Freeflow: "#05FF00",
  null: "white",
};

function MapComponent({ initialDrawings }) {
  const mapRef = useRef(null);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showEditConfirmModal, setShowEditConfirmModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [cameraPosition, setCameraPosition] = useState(null);
  const [cameraName, setCameraName] = useState("");
  const [cameraLocation, setCameraLocation] = useState("");
  const [cameraUrl, setCameraUrl] = useState("");
  const [cameraData, setCameraData] = useState([]);
  const [showCameraModal, setShowCameraModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [isTwoLanes, setIsTwoLanes] = useState(false);
  const [editedCamera, setEditedCamera] = useState({ cam_name: '', cam_location: '', cam_url: '' });

  const updateMarkerIcon = (marker, tcongestion, camera) => {
    const isTwoLanes = camera.two_lanes == 1 || camera.two_lanes == '1';
    if (isTwoLanes) {
      const lane1Congestion = tcongestion.lane1 || null;
      const lane2Congestion = tcongestion.lane2 || null;
      const color1 = tcongestionColor[lane1Congestion] || "black";
      const color2 = tcongestionColor[lane2Congestion] || "black";
      const canvas = document.createElement('canvas');
      canvas.width = 30; // Reduced size
      canvas.height = 30;
      const context = canvas.getContext('2d');
      context.beginPath();// Draw left half (Lane 1)
      context.arc(15, 15, 12, 0.5 * Math.PI, 1.5 * Math.PI); // Adjusted radius
      context.closePath();
      context.fillStyle = color1;
      context.fill();
      context.beginPath();// Draw right half (Lane 2)
      context.arc(15, 15, 12, 1.5 * Math.PI, 0.5 * Math.PI);
      context.closePath();
      context.fillStyle = color2;
      context.fill();
      context.beginPath(); // Draw the border
      context.arc(15, 15, 12, 0, 2 * Math.PI);
      context.closePath();
      context.lineWidth = 3; // Border weight
      context.strokeStyle = '#000';
      context.stroke();
      const iconUrl = canvas.toDataURL();
      marker.setIcon({
        url: iconUrl,
        scaledSize: new window.google.maps.Size(16, 16), // Adjusted for smaller appearance
      });
    } else {
      const congestionLevel = tcongestion.lane1 || tcongestion;
      const backgroundColor = tcongestionColor[congestionLevel] || "black";
      const customMarker = {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: backgroundColor,
        fillOpacity: 1,
        scale: 6.5, // Adjusted for smaller size
        strokeColor: "#000",
        strokeWeight: 1.6, // Consistent border weight
      };
      marker.setIcon(customMarker);
    }
  };

  const { continuouslyFetchCongestionData, clearIntervalForCamera } = useFetchCongestionData(cameraData, updateMarkerIcon); // Use the custom hook
  const fetchCameraData = () => {
    axiosphp.get("/fetch_traffic_cameras.php")
      .then(response => {
        setCameraData(response.data);
      })
      .catch(error => {
        console.error("Error fetching camera data:", error);
      });
  };

  useEffect(() => {
    fetchCameraData();
  }, []);

  const loadMap = () => {
    if (!window.google || !window.google.maps) {
      console.error("Google Maps JavaScript API not loaded.");
      return;
    }

    const map = new window.google.maps.Map(document.getElementById("mapstyle"), {
      center: { lat: 11.2416, lng: 125.0028 },
      zoom: 13,
    });

    cameraData.forEach(camera => {
      const marker = new window.google.maps.Marker({
        position: { lat: parseFloat(camera.cam_latitude), lng: parseFloat(camera.cam_longitude) },
        map: map,
        title: camera.cam_name,
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillColor: "white",
          fillOpacity: 1,
          scale: 6.5,
          strokeColor: "#000",
          strokeWeight: 1.6,
        }
      });
      continuouslyFetchCongestionData(camera, marker);
      marker.addListener("click", () => {
        setSelectedCamera(camera);
        setShowCameraModal(true);
      });
    });

    if (initialDrawings && initialDrawings.length > 0) {
      initialDrawings.forEach((data) => {
        let overlay;
        switch (data.type) {
          case window.google.maps.drawing.OverlayType.MARKER:
            overlay = new window.google.maps.Marker({
              position: data.position,
              map: map,
              icon: {
                url: markerIcons[data.name] || null,
                scaledSize: new window.google.maps.Size(50, 30),
              },
              label: data.name,
            });
            break;
          case window.google.maps.drawing.OverlayType.CIRCLE:
            overlay = new window.google.maps.Circle({
              center: data.position,
              radius: data.size,
              map: map,
              strokeColor: data.color,
              fillColor: data.color,
              fillOpacity: 0.35,
              strokeWeight: 3,
            });
            break;
          case window.google.maps.drawing.OverlayType.POLYGON:
            overlay = new window.google.maps.Polygon({
              paths: data.position,
              map: map,
              strokeColor: data.color,
              fillColor: data.color,
              fillOpacity: 0.35,
              strokeWeight: 3,
            });
            break;
          case window.google.maps.drawing.OverlayType.POLYLINE:
            overlay = new window.google.maps.Polyline({
              path: data.position,
              map: map,
              strokeColor: data.color,
              strokeWeight: 4,
            });
            break;
          case window.google.maps.drawing.OverlayType.RECTANGLE:
            overlay = new window.google.maps.Rectangle({
              bounds: data.position,
              map: map,
              strokeColor: data.color,
              fillColor: data.color,
              fillOpacity: 0.35,
              strokeWeight: 3,
            });
            break;
          default:
            console.warn(`Unsupported overlay type: ${data.type}`);
            return;
        }
      });
    }

    map.addListener("dblclick", (event) => {
      setCameraPosition({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
      setShowModal(true);
    });
    mapRef.current = map;
  };

  useEffect(() => {
    if (!window.google || !window.google.maps || !window.google.maps.drawing) {
      console.error("Google Maps JavaScript API or Drawing library not loaded.");
      return;
    }

    loadMap();
  }, [cameraData, initialDrawings, navigate]);

  const handleAddCamera = () => {
    if (!cameraName || !cameraLocation || !cameraUrl || !cameraPosition) {
      alert("Please fill out all fields");
      return;
    }
    setShowConfirmModal(true);
  };

  const confirmAddCamera = () => {
    const newCamera = {
      camname: cameraName,
      camlocation: cameraLocation,
      camurl: cameraUrl,
      lat: cameraPosition.lat.toFixed(6),
      lng: cameraPosition.lng.toFixed(6),
      twolanes: isTwoLanes ? 1 : 0,
      lane_position: JSON.stringify([
        { x: 30, y: 70 },
        { x: 1170, y: 70 },
        { x: 1170, y: 570 },
        { x: 30, y: 570 }
      ]),
      lane_position_two: isTwoLanes
        ? JSON.stringify([
          { x: 488, y: 182 },
          { x: 836, y: 179 },
          { x: 864, y: 450 },
          { x: 452, y: 453 }
        ])
        : null, 
    };

    axiosphp
      .post("/add_traffic_camera.php", newCamera)
      .then((response) => {
        if (response.data.success) {
          toast.success("Camera added successfully", { autoClose: 3000 });
          setCameraName("");
          setCameraLocation("");
          setCameraUrl("");
          fetchCameraData(); 
          axios.post('/update_camera_data') 
            .then((response) => {
              if (response.data.success) {
                console.log("Camera data updated successfully.");
              } else {
                console.error("Failed to update camera data:", response.data.message);
              }
            })
            .catch((error) => {
              console.error("Error updating camera data:", error);
            });
        } else {
          console.error("Failed to add camera:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error adding camera:", error);
      });
    setShowModal(false);
    setShowConfirmModal(false);
  };

  const handleWatchCamera = () => {
    if (selectedCamera) {
      if (selectedCamera.two_lanes === "1") {
        navigate(`/liveinterfacetwo/${selectedCamera.cam_id}`, { state: { camera: selectedCamera } });
      } else {
        navigate(`/liveinterface/${selectedCamera.cam_id}`, { state: { camera: selectedCamera } });
      }
    }
    setShowCameraModal(false);
  };

  const handleEditCamera = () => {
    if (selectedCamera) {
      setEditedCamera({
        cam_name: selectedCamera.cam_name,
        cam_location: selectedCamera.cam_location,
        cam_url: selectedCamera.cam_url,
      });
      setShowEditModal(true);
    }
    setShowCameraModal(false);
  };

  const handleEditCameraChange = (e) => {
    setEditedCamera({ ...editedCamera, [e.target.name]: e.target.value });
  };

  const handleEditSubmit = () => {
    setShowEditConfirmModal(true);
  };

  const confirmEditCamera = async () => {
    try {
      const response = await axiosphp.post('/edit_traffic_camera.php', {
        cam_id: selectedCamera.cam_id,
        cam_name: editedCamera.cam_name,
        cam_location: editedCamera.cam_location,
        cam_url: editedCamera.cam_url,
      });

      if (response.data.success) {
        toast.success("Camera edited successfully", { autoClose: 3000 });
        fetchCameraData();
        
        await axios.post('/update_camera_data');

      } else {
        console.error('Failed to edit camera:', response.data.message);
      }
    } catch (error) {
      console.error('Error editing camera:', error);
    } finally {
      setShowEditConfirmModal(false);
      setShowEditModal(false);
    }
  };

  const handleDeleteCamera = async () => {
    if (selectedCamera) {
      try {
        const response = await axiosphp.post('/delete_traffic_camera.php', {
          cam_id: selectedCamera.cam_id,
        });

        if (response.data.success) {
          toast.success("Camera deleted successfully", { autoClose: 3000 });
          clearIntervalForCamera(selectedCamera.cam_id);
          setCameraData(prevData => prevData.filter(camera => camera.cam_id !== selectedCamera.cam_id));
          axios.post('/update_camera_data')
            .then((response) => {
              if (response.data.success) {
                console.log("Camera data updated successfully after deletion.");
                setCameraData((prevData) =>
                  prevData.filter((camera) => camera.cam_id !== selectedCamera.cam_id)
                );
              } else {
                console.error("Failed to update camera data:", response.data.message);
              }
            })
            .catch((error) => {
              console.error("Error updating camera data after deletion:", error);
            });
        } else {
          console.error('Failed to delete camera:', response.data.message);
        }
      } catch (error) {
        console.error('Error deleting camera:', error);
      } finally {
        setShowDeleteConfirmModal(false);
        setShowCameraModal(false);
      }
    }
  };
  const handleShowDeleteConfirmModal = () => {
    setShowDeleteConfirmModal(true);
  };

  return (
    <div>
      <ToastContainer  closeButton={false}/>
      <div id="mapstyle" className="mapstyle"></div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Camera</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Camera Name</Form.Label>
              <Form.Control
                type="text"
                value={cameraName}
                onChange={(e) => setCameraName(e.target.value)}
                placeholder="Enter camera name"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Camera Location</Form.Label>
              <Form.Control
                type="text"
                value={cameraLocation}
                onChange={(e) => setCameraLocation(e.target.value)}
                placeholder="Enter camera location"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Camera URL</Form.Label>
              <Form.Control
                type="text"
                value={cameraUrl}
                onChange={(e) => setCameraUrl(e.target.value)}
                placeholder="Enter camera URL"
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Two Lanes"
                checked={isTwoLanes}
                onChange={(e) => setIsTwoLanes(e.target.checked)}
              />
            </Form.Group>
            <p>
              Latitude: {cameraPosition && cameraPosition.lat.toFixed(6)} <br />
              Longitude: {cameraPosition && cameraPosition.lng.toFixed(6)}
            </p>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddCamera}>
            Add Camera
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Add Camera</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to add this camera?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmAddCamera}>
            Yes, Add Camera
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCameraModal} onHide={() => setShowCameraModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Camera Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>What would you like to do with this camera?</p>
          <Button variant="primary" onClick={handleWatchCamera}>
            Watch Camera
          </Button>
          <Button variant="secondary" onClick={handleEditCamera}>
            Edit Camera
          </Button>
          <Button variant="danger" onClick={handleShowDeleteConfirmModal}>
            Delete Camera
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCameraModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Camera</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Camera Name</Form.Label>
              <Form.Control
                type="text"
                name="cam_name"
                value={editedCamera.cam_name || ''}
                onChange={handleEditCameraChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Camera Location</Form.Label>
              <Form.Control
                type="text"
                name="cam_location"
                value={editedCamera.cam_location || ''}
                onChange={handleEditCameraChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Camera URL</Form.Label>
              <Form.Control
                type="text"
                name="cam_url"
                value={editedCamera.cam_url || ''}
                onChange={handleEditCameraChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleEditSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditConfirmModal} onHide={() => setShowEditConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to save these changes?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmEditCamera}>
            Yes, Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteConfirmModal} onHide={() => setShowDeleteConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this camera?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteCamera}>
            Yes, Delete Camera
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default MapComponent;